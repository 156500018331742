import moment from 'moment';

export const isBrowser = () => typeof window !== "undefined";

export const isLoggedIn = () => {
  if (isBrowser() && window.localStorage.getItem("raftUser")) {
      const d = JSON.parse(window.localStorage.getItem("raftUser"));
      if (d === false)
        return false;
      return moment(d).isAfter(moment().subtract(24, 'hours'));
  }  
  return false;
}




export const handleLogin = (password) => {
  if (password === "togetherwearemighty") {
    window.localStorage.setItem("raftUser", JSON.stringify(new Date()))
    return true;
  }
  return false
}

export const logout = (callback) => {
  window.localStorage.setItem("raftUser", JSON.stringify(false));
  if (callback != null)
      callback()
}