import React from 'react';
import { navigate } from "gatsby"
import './style.scss';
import { handleLogin, isLoggedIn } from "../../../services/Auth";
import InputWrap from '../InputWrap/InputWrap';


class SignIn extends React.Component {
    state = {
        password: null,
        error: false
    }

    _handleChange = (e) => {
        this.setState({
            [`${e.target.name}`]: e.target.value,
        });
    }

    _handleSubmit = (e) => {
        e.preventDefault();
        if (handleLogin(this.state.password) && typeof window !== "undefined") {
            this.setState({error: false});  // Unnecessary, but just in case navigate fails
            navigate(this.props.in);
        } else {
            // Show some sort of error
            this.setState({error: true});
            console.log("Incorrect password");
        }
    }

    render() {
        // If you're already signed in - redirect to the resources page like on submit
        // On the other page, if you're not, redirect to the page with this
        if (isLoggedIn() && typeof window !== "undefined") {
            console.log("Already logged in, let's progress");
            navigate(this.props.in);
            return null;
        }
        return (
            <div className="blockwrap">
                <div className="base-styles sign-in">
                    <div className="content">
                        <h1 className="animate-underline">Login</h1>
                        <form onSubmit={this._handleSubmit}>
                            <InputWrap error={this.state.error} message="Invalid password">
                                <label htmlFor="password">Password</label>
                                <input
                                    type="password"
                                    onChange={this._handleChange}
                                    name="password"
                                />
                            </InputWrap>
                            <button class="submit-btn btn-basic" type="submit" name="submit">Login <i class="fa fa-long-arrow-right"></i></button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
export default SignIn;