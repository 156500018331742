import React from 'react';
import './style.scss';
import Wrapper from '../../templates/Wrapper/Wrapper';
import FeaturedImage from "../../components/FeaturedImage/FeaturedImage"
import EmailSignUp from '../../components/Forms/EmailSignUp/EmailSignUp';
import SignIn from '../../components/Forms/SignIn/SignIn';
import SEO from '../../components/seo';
import PotentiallyPrivate from '../../components/Private';

const Engage = ({pageContext}) => {
    
    let downloadPdf = null;

    // Download PDF

    if(pageContext.acf.download_pdf){
        downloadPdf = <a href={pageContext.acf.download_pdf.source_url} className="btn-basic ">Download PDF<i className="fa fa-download"></i></a>; 
    }
    
    let emailSignUp = pageContext.acf.email_sign_up ? <EmailSignUp groupid={pageContext.acf.groupid} cta_text={pageContext.acf.cta_text} phone={true} /> : null;
    let SignInForm = pageContext.slug === "volunteers-center" ? <SignIn in="resources/volunteers-center-resources"  /> : null;
    return (
       <PotentiallyPrivate pageContext={pageContext}>
        <Wrapper pageCurrent={ `engage ${pageContext.title.toLowerCase()} ${pageContext.slug}` }>
            <SEO title={pageContext.title} />
            <div className="blockwrap menu-spacer">
                <FeaturedImage pageContext={pageContext} /> 
            </div>

            <div className="blockwrap">
                
                <div className="container base-styles">
                    <div className="content">
                        <div className="inner-heading-wrap">
                            <h1 className="animate-underline" dangerouslySetInnerHTML={{__html: pageContext.title}}></h1>
                        </div>
                        <div className="e" dangerouslySetInnerHTML={{__html: pageContext.content}} />
                        {downloadPdf}

                    </div> 
                </div>
            
            </div>
            {SignInForm}
            {emailSignUp}
               
        </Wrapper>
        </PotentiallyPrivate>
    )

}
export default Engage;