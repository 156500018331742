import React from "react"
import { navigate } from "gatsby"
import { isLoggedIn, logout } from "../services/Auth"

const Private = (props) => {
  if (!isLoggedIn() && props.pageContext.slug === `volunteers-center-resources`) {
      if (typeof window !== "undefined")
        navigate("/resources/volunteers-center");
      return null;
  }
  const logoutdebug = isLoggedIn() && window !== undefined && window.location.hash.includes('show-logout') ? <button onClick={function() {logout(()=>{console.log("Logout called", isLoggedIn())});}}>Logout</button> : null;
  return ( 
    <React.Fragment>
        {logoutdebug}
        {props.children}
    </React.Fragment>
  );
}
export default Private